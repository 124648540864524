<!-- <div class="kt-portlet" [ngClass]="customClass" #portlet> -->
<ng-container *ngIf="portletProgressOptions">
	<div
		tabindex="-1"
		*ngIf="portletProgressOptions.progress"
		[ngStyle]="{ 'background-color': portletProgressOptions.overlayBackdrop }"
		[class.kt-portlet-progress-overlay]="portletProgressOptions.overlay"
		class="kt-portlet-progress">
		<mat-spinner></mat-spinner>
	</div>
</ng-container>

<ng-content></ng-content>
<!-- </div> -->
