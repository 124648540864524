import { MathUtil } from '../classes/MathUtil';
import { Directive, Input, ElementRef, HostListener } from '@angular/core';

@Directive({
	selector: '[decimalNumber]',
})
export class DecimalNumberDirective {
	private decimalCounter = 0;
	private navigationKeys = ['Backspace', 'Delete', 'Tab', 'Escape', 'Enter', 'Home', 'End', 'ArrowLeft', 'ArrowRight', 'Clear', 'Copy', 'Paste'];

	@Input() decimal = true;
	@Input() negative = false;
	@Input() decimalSeparator = '.';
	@Input() milesSeparator = ',';
	@Input() decimalMaxDigits = 2;
	inputElement: HTMLInputElement;

	mathUtil: MathUtil = new MathUtil();

	constructor(public el: ElementRef) {
		this.inputElement = el.nativeElement;
	}

	@HostListener('focusout', ['$event'])
	onFocusOut(e: FocusEvent) {
		let formatted = this.mathUtil.formatDecimal(this.el.nativeElement.value, this.decimalMaxDigits);
		if (isNaN(formatted)) {
			formatted = 0;
		}
		const sanitizedContent = this.sanatizeInput(formatted.toFixed(this.decimalMaxDigits));
		//console.log(this.inputElement.selectionEnd); //Toma la posición del del cursor dentro de input
		this.inputElement.setRangeText(sanitizedContent, 0, sanitizedContent.length, 'end');
	}

	@HostListener('keydown', ['$event'])
	onKeyDown(e: KeyboardEvent) {
		if (
			this.navigationKeys.indexOf(e.key) > -1 || // Allow: navigation keys: backspace, delete, arrows etc.
			(e.key === 'a' && e.ctrlKey) || // Allow: Ctrl+A
			(e.key === 'c' && e.ctrlKey) || // Allow: Ctrl+C
			(e.key === 'v' && e.ctrlKey) || // Allow: Ctrl+V
			(e.key === 'x' && e.ctrlKey) || // Allow: Ctrl+X
			(e.key === 'a' && e.metaKey) || // Allow: Cmd+A (Mac)
			(e.key === 'c' && e.metaKey) || // Allow: Cmd+C (Mac)
			(e.key === 'v' && e.metaKey) || // Allow: Cmd+V (Mac)
			(e.key === 'x' && e.metaKey) || // Allow: Cmd+X (Mac)
			e.key === '+' ||
			e.key === (this.negative ? '-' : '+') ||
			(this.decimal && e.key === this.decimalSeparator && this.decimalCounter < 1) || // Allow: only one decimal point
			e.key === this.milesSeparator // Allow: only one comma
		) {
			// let it happen, don't do anything
			return;
		}
		// Ensure that it is a number and stop the keypress
		if (e.key === ' ' || isNaN(Number(e.key))) {
			e.preventDefault();
		}
	}

	@HostListener('keyup', ['$event'])
	onKeyUp(e: KeyboardEvent) {
		if (!this.decimal) {
			return;
		} else {
			this.decimalCounter = this.el.nativeElement.value.split(this.decimalSeparator).length - 1;
		}
	}

	@HostListener('paste', ['$event'])
	onPaste(event: ClipboardEvent) {
		const pastedInput = event.clipboardData?.getData('text/plain');
		if (pastedInput) this.pasteData(pastedInput);
		event.preventDefault();
	}

	@HostListener('drop', ['$event'])
	onDrop(event: DragEvent) {
		const textData = event.dataTransfer?.getData('text');
		this.inputElement.focus();
		if (textData) this.pasteData(textData);
		event.preventDefault();
	}

	private pasteData(pastedContent: string): void {
		const sanitizedContent = this.sanatizeInput(pastedContent);
		const pasted = document.execCommand('insertText', false, sanitizedContent);
		if (!pasted) {
			const { selectionStart: start, selectionEnd: end } = this.inputElement;
			if (start && end) this.inputElement.setRangeText(sanitizedContent, start, end, 'end');
		}
	}

	private sanatizeInput(input: string): string {
		let result = '';
		let expRegNegative = '[+]?';
		if (this.negative) {
			expRegNegative = '[+-]?';
		}
		if (this.decimal && this.isValidDecimal(input)) {
			const regex = new RegExp(`/^${expRegNegative}[0-9${this.decimalSeparator}]`, 'g');
			result = input.replace(regex, '');
		} else {
			// console.log('2');
			result = input.replace(`/^${expRegNegative}[0-9]/g`, '');
		}
		return result;
	}

	private isValidDecimal(string: string): boolean {
		return string.split(this.decimalSeparator).length <= 2;
	}
}
