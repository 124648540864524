import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'dataSize' })
export class DataSizePipe implements PipeTransform {
	transform(value: number, ...args: number[]): string {
		if (value == null || value === 0) {
			return '0 Bytes';
		}

		const decimals = args[0] ?? 2;

		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(value) / Math.log(k));

		return `${String(parseFloat((value / Math.pow(k, i)).toFixed(dm)))} ${sizes[i]}`;
	}
}
