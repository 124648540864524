import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// import { DisabledControlDirective } from '@utils/directives/disabled-control.directive';
// import { ScNotImageDirective } from './directives/sc-not-image.directive';
import { DisabledControlDirective, ScNotImageDirective } from '@utils/directives/index';
import { FilterPipe } from './pipes/filter.pipe';
import { DataSizePipe } from './pipes/data-size.pipe';

@NgModule({
	imports: [CommonModule],
	exports: [DisabledControlDirective, ScNotImageDirective, FilterPipe, DataSizePipe],
	declarations: [DisabledControlDirective, ScNotImageDirective, FilterPipe, DataSizePipe],
	providers: [],
})
export class UtilsModule {}
