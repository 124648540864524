// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';

@Injectable({
	providedIn: 'root',
})
export class LayoutRefService {
	layoutRefs$: BehaviorSubject<any> = new BehaviorSubject<any>({});
	layoutRefs: any = {};
	addElement(name: any, element: any) {
		const obj = {};
		obj[name as keyof Object] = element;

		this.layoutRefs = Object.assign({}, this.layoutRefs, obj);
		this.layoutRefs$.next(this.layoutRefs);
	}
}
