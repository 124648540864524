// Angular
import { Injectable } from '@angular/core';
// RxJS
import { BehaviorSubject } from 'rxjs';
// Object path
import * as objectPath from 'object-path';
// Services
import { currentMenu } from '@core/store/selectors/menu.selectors';
import { map } from 'rxjs/operators';
import { AppState } from '@core/store';
import { Store } from '@ngrx/store';

@Injectable({
	providedIn: 'root',
})
export class MenuHorizontalService {
	public sciMenu: any = {
		header: {
			self: {},
			items: [],
		},
	};

	// Public properties
	menuList$: BehaviorSubject<any[]> = new BehaviorSubject<any[]>([]);

	bartype: any;
	constructor(private store: Store<AppState>) {
		this.loadMenu();
	}

	setconfig(data: any) {
		this.bartype = data;
	}

	loadMenu(): BehaviorSubject<any[]> {
		this.store
			.select(currentMenu)
			.pipe(
				map((res) => {
					this.sciMenu.header.items = [];
					this.sciMenu.header.items = res;
					const menuItems: any[] = objectPath.get(this.sciMenu, 'header.items');
					this.menuList$.next(menuItems);
				})
			)
			.subscribe();

		return this.menuList$;
	}
}
