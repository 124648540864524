// Angular
import { Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';

@Component({
	selector: 'kt-portlet-footer',
	template: `<ng-content></ng-content>`,
	host: {
		class: 'kt-portlet-footer',
	},
	styles: [
		`
			.kt-portlet-footer {
				display: block;
				overflow: hidden;
				border-top: 1px solid #ebedf2;
			}
		`,
	],
	encapsulation: ViewEncapsulation.None,
})
export class PortletFooterComponent implements OnInit {
	@Input() class!: string;

	/**
	 * @ Lifecycle sequences => https://angular.io/guide/lifecycle-hooks
	 */

	/**
	 * On init
	 */
	ngOnInit() {
		// if (this.class) {
		// 	this.classList += ' ' + this.class;
		// }
	}
}
