import { Directive } from '@angular/core';
import { ConfigService, ServiceCrud } from '@utils/interface/service.interface';
import { ApiResponse } from '@utils/models/ApiResponse';

import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';

/**
 * @deprecated
 */
type Path = 'insert' | 'update' | 'delete';

/**
 * Clase que implementa métodos `CRUD`
 * @abstract
 * @argument E `entidad`
 * @argument K `clave`
 */
@Directive()
export abstract class GenericService<E, K> implements ServiceCrud<E, K> {
	constructor(protected readonly _http: HttpClient) {}

	/**
	 * Método para configurar el servicio
	 *
	 * @usageNotes
	 *
	 * ### Esta configuración es obligatorio
	 *
	 * ```ts
	 * get configuration(): ConfigService {
	 *  return {
	 *      url: {
	 *          base: this.url
	 *      },
	 *      // Si el `K` es de tipo `number` retornar el mismo `PK`
	 *      getPK(pk:K):string {
	 *           return  `${pk}`
	 *      }
	 *  }
	 * }
	 *
	 * get configuration(): ConfigService {
	 *  return {
	 *      url: {
	 *          base: this.url
	 *      },
	 *      // Si el `K` es una `interfaces`
	 *      getPK(pk:K):string {
	 *           return  `${pk.property1}/${pk.property2}`
	 *      }
	 *  }
	 * }
	 * ```
	 *
	 * ### Puede agregar otras opciones pero no es necesario
	 *
	 * ```ts
	 * get configuration(): ConfigService {
	 *  return {
	 *      url: {
	 *          base: this.url
	 *      }
	 *  }
	 * }
	 * ```
	 *
	 */
	abstract get configuration(): ConfigService<K>;

	findById(pk: K): Observable<E> {
		try {
			return this._http.get<ApiResponse<E>>(`${this.configuration.url.base}/${this.configuration.getPK?.(pk)}`).pipe(map((res) => res.data));
		} catch {
			throw new Error('Method not implemented. configuration -> getPK');
		}
	}

	insert(entity: E): Observable<E> {
		return this._http.post<ApiResponse<E>>(`${this.configuration.url.base}`, entity).pipe(map((res) => res.data));
	}

	update(entity: E): Observable<E> {
		return this._http.put<ApiResponse<E>>(`${this.configuration.url.base}`, entity).pipe(map((res) => res.data));
	}

	delete(entity: E): Observable<E | null> {
		return this._http.delete<ApiResponse<void>>(`${this.configuration.url.base}`, { body: entity }).pipe(map((res) => (!res.error ? entity : null)));
	}
}

// abstract class GeneralErrorEnum {
//     static get IN000000(): ErrorEnum { return GeneralErrorEnum.ERROR("GEN000000", ""); }
//     static get AD000000(): ErrorEnum { return GeneralErrorEnum.ERROR("GEN000000", ""); }
//     static get AD000001(): ErrorEnum { return GeneralErrorEnum.ERROR("GEN000001", "Id no Existe"); }
//     static get ER000000(): ErrorEnum { return GeneralErrorEnum.ERROR("GEN000000", ""); }
//     static get ER000001(): ErrorEnum { return GeneralErrorEnum.ERROR("GEN000001", "Error al zipiar"); }
//     static get ER000018(): ErrorEnum { return GeneralErrorEnum.ERROR("ER000018", "Error al obtener la url del arhivo"); }

//     private static ERROR(code: string, message: string): ErrorEnum {
//         return {
//             getCode: code,
//             getMessage: message,
//         }
//     }
// }

// abstract class LoggerError {
//     static Error(error: ErrorEnum) {
//         console.error(error.getCode, error.getMessage)
//     }
//     static Warning(error: ErrorEnum) {
//         console.warn(error.getCode, error.getMessage)
//     }
// }

// GeneralErrorEnum.ER000018.getCode
