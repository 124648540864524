import { createAction, props } from '@ngrx/store';
import { SciOficina } from '~models/config/interface/SciOficina';
import { SciUsuarioOficinaPerfil } from '~models/config/interface/SciUsuarioOficinaPerfil';

export const cargarOficinas = createAction('[Oficinas] Cargar Oficinas');

export const cargarOficinasSuccess = createAction('[Oficinas] Cargar Oficinas Success', props<{ sciUsuarioOficinaPerfil: SciUsuarioOficinaPerfil[] }>());

export const cambiarOficinaSeleccionada = createAction('[Oficinas] Cambiar Oficina Seleccionada', props<{ sciOficina: SciOficina }>());

export const cargarOficinasError = createAction('[Oficinas] Cargar Oficinas Error', props<{ payload: any }>());

export const habilitarComboOficinas = createAction('[Oficinas] Habilitar combo oficinas');

export const deshabilitarComboOficinas = createAction('[Oficinas] Deshabilitar combo oficinas');
