import { Directive, ElementRef, HostListener } from '@angular/core';
import { environment } from 'environments/environment';

@Directive({
	selector: '[scNotImage]',
})
export class ScNotImageDirective {
	private _notImage: string = environment.CONFIG_IMAGE.IMAGE.NOT;

	@HostListener('error')
	error(): void {
		const imageElement = this._elmentRef.nativeElement;
		if (imageElement && imageElement instanceof HTMLImageElement) {
			imageElement.src = this._notImage;
			imageElement.alt = 'hubo un error al cargar la imagen';
		}
	}

	constructor(private _elmentRef: ElementRef<HTMLImageElement>) {}
}
