export enum EnumPermissionDefault {
	Modificar = 'ACC-MODIFICAR',
	Eliminar = 'ACC-ELIMINAR',
	Registrar = 'ACC-REGISTRAR',
	Listar = 'ACC-LISTAR',
	VerError = 'ACC-VER-ERROR',
}

export enum EnumPermissionRouting {
	CopiarRouting = 'ACC-COPIAR-ROUTING',
	FleteCargo = 'ACC-FLETE-CARGOS',
	DetalleRouting = 'ACC-VER-RESUMEN',
	Seguimiento = 'ACC-IR-SEGUIMIENTO',
	EmitirDocumento = 'ACC-EMISION-DOCUMENTO',
	GestionArchivo = 'ACC-GESTION-ARCHIVO',
}

export enum EnumPermissionEmisionElectronica {
	EnviarManifiesto = 'ACC-ENVIAR-MANIFIESTO',
	Seguimiento = 'ACC-IR-SEGUIMIENTO',
	Descarga = 'ACC-DESCARGA',
	DetalleRouting = 'ACC-VER-RESUMEN',
}

export enum EnumPermissionNave {
	SubirImagen = 'ACC-SUBIR-IMAGEN',
}

export enum EnumPermisionProvisionAdm {
	GenerateCompra = 'ACC-GENERAR-COMPRA',
	GenerateVenta = 'ACC-GENERAR-VENTA',
}

export enum EnumPermissionSeguimientoOperativo {
	AgregarIncidencia = 'ACC-AGREGAR-INCIDENCIA',
	ModificarIncidencia = 'ACC-MODIFICAR-INCIDENCIA',
	EjecutarIncidencia = 'ACC-EJECUTAR-INCIDENCIA',
}

export enum EnumPermissionConfigSeguimientoOperativo {
	AgregarIncidencia = 'ACC-AGREGAR-INCIDENCIA',
	ControlPlazos = 'ACC-CONTROL-PLAZOS',
}

export enum EnumPermissionPlantilla {
	ConfigurarDocumento = 'ACC-CONFIGURAR-DOCUMENTO',
	SubirPlantilla = 'ACC-SUBIR-PLANTILLA',
	DescargarPlantilla = 'ACC-DESCARGAR-PLANTILLA',
	RemoverPlantilla = 'ACC-REMOVER-PLANTILLA',
}

export enum EnumPermissionEmisionDocuments {
	Listar = 'ACC-LISTAR',
	Configurar = 'ACC-CONFIGURAR-DOCUMENTO',
	Emitir = 'ACC-EMITIR-DOCUMENTOS',
}
