// Angular
import { Pipe, PipeTransform } from '@angular/core';
@Pipe({
	name: 'firstLetter',
})
export class FirstLetterPipe implements PipeTransform {
	transform(value: string, isReverseActive?: boolean): string {
		if (value) {
			if (!isReverseActive) {
				return value
					.split(' ')
					.map((n) => n[0])
					.join('');
			}
			return value
				.split(/[\s,]+/)
				.map((n) => n[0])
				.reverse()
				.join('');
		} else {
			return '';
		}
	}
}
