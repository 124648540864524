import { Directive, Input, OnChanges, SimpleChanges } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
	selector: '([formControlName], [formControl])[disabledControl], [disabledControl]',
})
export class DisabledControlDirective implements OnChanges {
	// @Input() set disabledControl(state: boolean) {
	//   const action = state ? 'disable' : 'enable'
	//   setTimeout(() => {
	//     this.ngControl.control[action]()
	//   });
	// }

	@Input() disabledControl = false;

	constructor(private readonly ngControl: NgControl) {}

	ngOnChanges(changes: SimpleChanges): void {
		if (changes.disabledControl) {
			const action = this.disabledControl ? 'disable' : 'enable';
			this.ngControl?.control?.[action]();
		}
	}
}
