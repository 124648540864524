/* eslint-disable @typescript-eslint/no-explicit-any */
import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name: 'filter',
})
export class FilterPipe implements PipeTransform {
	transform(data: any[] | null, ...args: any[]): any[] {
		if (data == null) return [];
		if (args[0] === null || args[0] === undefined) throw new Error('Se necesita un argumento para realizar el filtro. Example filter:arg.');

		const res = [];
		const valueFilter = (args[0] as string).toUpperCase();

		if (valueFilter !== '') {
			for (let i = 0; i < data.length; i++) {
				const element = data[i];

				for (const key in element) {
					if (Object.hasOwnProperty.call(element, key)) {
						const k = key;

						let e = element[k];

						e = e ? e : '';
						if (e.toString().toUpperCase().indexOf(valueFilter) >= 0) {
							res.push(data[i]);
							break;
						}
					}
				}
			}

			return res;
		}

		return data;
	}
}
