// NGRX
import { createSelector } from '@ngrx/store';

import { AppState } from '@core/store';

export const selectOficinasState = (state: AppState) => state.oficinas;

export const currentOffices = createSelector(selectOficinasState, (oficinas) => oficinas.oficinas);

export const oficinaSelected = createSelector(selectOficinasState, (oficinas) => oficinas.oficina);

export const loadedOffices = createSelector(selectOficinasState, (oficinas) => oficinas.loaded);

export const disabledOffices = createSelector(selectOficinasState, (oficinas) => oficinas.disabled);
