import { environmentBase, mergeEnviroments } from './environment.base';

export const environment = {
	production: true,
	...mergeEnviroments(environmentBase, {
		URL_LOGIN: 'https://dev-login.sintad.net.pe/#/auth/login',
		HOST_SECURITY: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-security/api/security',
		HOST_CONFIG: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-config/api',
		HOST_MAESTROS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-maestros/api',
		HOST_CARGA: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-carga/api',
		HOST_INFO_OPER: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-informacion-operativa/api',
		HOST_DOC_OPER: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-documentos-operativos/api',
		HOST_EMISION_ELECTRONICA: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-emision-electronica/api',
		HOST_VENTAS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-ventas/api',
		HOST_COMPRAS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-compras/api',
		HOST_BANCOS: 'https://api-cluster.sumax.pe/dev/sumax-erp-backend-bancos/api',
		HOST_MAESTROS_SYNC: 'https://api-cluster-sistemas-internos.sumax.pe/dev/sumax-erp-backend-maestros-sync/api',

		URL_MAESTROS: 'https://dev-maestros.sintad.net.pe/#/maestros',
		HOST_DMS_WEB: 'https://dev-dms.sintad.net.pe',
		URL_DOC_OPER: 'https://dev-documentos-operativos.sintad.net.pe/',
	}),
	MQTT_PASSWORD: 'scmj010400*212598',
};
