// Anglar
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
// Layout Directives
// Services
import {
	FirstLetterPipe,
	ToggleDirective,
	ScrollTopDirective,
	StickyDirective,
	// SparklineChartDirective,
	LineBreakPipe,
} from './_base/layout/index';
import { OffcanvasDirective } from './_base/layout/directives/offcanvas.directive';
import { MenuDirective } from './_base/layout/directives/menu.directive';
import { ContentAnimateDirective } from './_base/layout/directives/content-animate.directive';

@NgModule({
	imports: [CommonModule],
	declarations: [
		// directives
		ToggleDirective,
		OffcanvasDirective,
		MenuDirective,
		ContentAnimateDirective,
		StickyDirective,
		ScrollTopDirective,
		// SparklineChartDirective,
		// pipes
		FirstLetterPipe,
		LineBreakPipe,
	],
	exports: [
		// directives
		ToggleDirective,
		OffcanvasDirective,
		MenuDirective,
		ContentAnimateDirective,
		StickyDirective,
		ScrollTopDirective,
		// SparklineChartDirective,
		// pipes
		FirstLetterPipe,
		LineBreakPipe,
	],
	providers: [],
})
export class CoreModule {}
