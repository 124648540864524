// NGRX
import { createSelector } from '@ngrx/store';

import { AppState } from '@core/store';

export const selectMenuState = (state: AppState) => state.menu;

export const currentMenu = createSelector(selectMenuState, (menu) => menu.menu);

export const menuLoading = createSelector(selectMenuState, (menu) => menu.loading);
