import { RouterStateUrl } from './../serializer/custom-serializer';
import { AppState } from '@core/store';
import * as fromRouter from '@ngrx/router-store';
import { createSelector } from '@ngrx/store';

export const getRouterState = (state: AppState) => state.router;

export const getCurrentRouteState = createSelector(getRouterState, (state: fromRouter.RouterReducerState<RouterStateUrl>) => state?.state);

export const oficinaParams = createSelector(getRouterState, (state: fromRouter.RouterReducerState<RouterStateUrl>) => state?.state.oficinaParams);
